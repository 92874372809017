body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    position: relative;
    min-height: 100vh;
    /* Establece un mínimo de altura igual al 100% de la altura de la ventana */
    margin-bottom: 100px;
    /* Ajusta el valor según la altura de tu footer */
}

header {
    background-color: white;
    color: white;
    text-align: center;
    padding: 1rem;
}

header img {
    width: 300px;
}

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #0C2748;
    padding: 0.5rem;
    position: relative;
}

.menu-toggle {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
}

.menu-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #0C2748;
    padding: 1rem;
    box-sizing: border-box;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.menu-options.show {
    opacity: 1;
    visibility: visible;
}

.menu-options.hide {
    display: none;
    visibility: hidden;
    opacity: 0;
}

.menu-options a {
    color: white;
    text-decoration: none;
    padding: 1rem;
    display: block;
}

.menu-options a:hover {
    background-color: #174A78;
}

footer {
    background-color: #0C2748;
    color: white;
    text-align: center;
    padding: 1rem;
    position: absolute;
    bottom: -16rem;
    width: 100%;
    height: auto;
    /* Cambiado a auto para ajustarse al contenido */
}

.footer-columns {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-column {
    flex: 1;
    max-width: 300px;
    margin: 0 10px;
}

.footer-link {
    display: block;
    color: white;
    text-decoration: none;
    margin-bottom: 10px;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-icons {
    display: flex;
    margin-top: 20px;
    margin-bottom: -25px;
    margin-left: 78px;

}

.footer-icons svg {
    font-size: 24px;
    margin-right: 8px;
    color: white;
}

.footer-column.mobile {
    display: none;
}

.separation-space {
    margin-bottom: 20px;
    /* Ajusta según sea necesario */
}

.home-content {
    display: flex;
    margin-top: 7rem;
    margin-left: 8rem;
    margin-right: 1rem;
}

.home-content-left {
    flex: 1;
    margin-left: 38px;

}

.home-content-right {
    flex: 1;
    margin-left: 38px;
    margin-right: 37px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}

.home-content-right img {
    vertical-align: middle;
    width: 447px;
}

.home-content-left h2 {
    font-size: calc(2.325rem + .9vw);
    font-weight: 600;
}

.home-content-left p {
    margin-top: 0;
    margin-bottom: 1rem;

    font-size: 23px;

}

.conocenos-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0C2748;
    /* Color azul */
    color: #fff;
    /* Color blanco para el texto */
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.conocenos-button:hover {
    background-color: #0C2748;
    /* Cambia el color al pasar el mouse */
}

.body-home h1 {
    text-align: center;
    font-weight: 900;
}

.centered-section {
    display: flex;
    justify-content: space-between;
    /* max-width: 800px; */
    /* margin-top: -2rem; */
    /* margin-left: 9rem; */

    padding: 69px 158px 69px 158px;
    /* margin-right: 11rem; */
    background-color: #f7f7f7;
}

.left-box,
.center-box,
.right-box {
    text-align: center;
    margin-left: 3rem;
    margin-right: 3rem;
}

.left-box p {
    color: #0C2748;
    font-size: 21px;
}

.center-box p {
    color: #0C2748;
    font-size: 21px;
}

.right-box p {
    color: #0C2748;
    font-size: 21px;
}

.centered-oficial {
    display: flex;
    justify-content: center;
    /* max-width: 800px; */
    margin-top: 4rem;
    /* margin-left: 9rem; */
    padding: 69px 158px 50px 98px;
    /* margin-right: 11rem; */
    background-color: #f7f7f7;
    box-shadow: 0 0 5px rgba(0, 0, 0, 1.1);
    align-items: center;
}

.icon {
    font-size: 2rem;
    /* Ajusta el tamaño del icono según sea necesario */
    margin-bottom: 10px;
    /* Ajusta el espacio entre el icono y el texto */
    color: #0C2748;
}

img,
svg {
    vertical-align: middle;

}

.left-box-oficial,
.right-box-oficial {
    text-align: center;
    margin-left: 26rem;
    margin-right: 6rem;
}

.center-box-oficial {
    text-align: center;
    margin-right: 22rem;
}

.left-box-oficial img {
    width: 16rem;
}

.center-box-oficial img {
    width: 8rem;
    margin-top: -10px;
}

.right-box-oficial img {
    width: 16rem;
}

.whatsapp-button-container {
    position: fixed;
    bottom: 33px;
    right: 44px;
    background-color: #25d366;
    border-radius: 50%;
    padding: 13px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.whatsapp-icon {
    color: #fff;
    /* Puedes ajustar el color según tus necesidades */
    font-size: 58px;
}

.img-zf-car img {
    display: flex;
    gap: 44px;
    flex-wrap: wrap;
    position: relative;
    margin-left: 7rem;
    margin-right: 5rem;
    max-width: 100%;
    max-height: 100%;
    padding: 1rem 1rem 1rem 15rem;
}




@media screen and (max-width: 768px) {
    .home-content {
        flex-direction: column;
    }

    .centered-section {
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
        padding: 65px 63px 69px 56px;

    }

    .left-box,
    .center-box,
    .right-box {
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 2rem;
    }

    .centered-oficial {
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
        padding: 65px 63px 69px 56px;

    }

    .left-box-oficial,
    .right-box-oficial {
        text-align: center;
        margin-left: 3rem;
        margin-right: 3rem;
        margin-top: 2rem;
    }

    .center-box-oficial {
        text-align: center;
        margin-left: 3rem;
        margin-right: 3rem;
        /* padding-top: 14px; */
        /* margin-top: 19px; */
        padding-bottom: 55px;
        padding-top: 55px;
    }



}

@media only screen and (max-width: 375px) and (max-height: 667px) {
    .img-zf-car img {
        padding: 0;
        /* Elimina el padding para pantallas de 375x667 */
        margin-left: 0rem;
        /* Ajusta este margen según tus necesidades para móviles */
        margin-right: 0rem;
        /* Ajusta este margen según tus necesidades para móviles */
    }
}

@media screen and (max-width: 768px) {
    .menu-options {
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #0C2748;
        box-sizing: border-box;
        z-index: 2;
    }

    .menu-options a {
        text-align: center;
        width: 100%;
        padding: 1rem;
    }

    .menu-toggle {
        display: block;
        cursor: pointer;
        font-size: 1.5rem;
        color: white;
    }


    .menu-options a {
        color: white;
        text-decoration: none;
        padding: 1rem;
        display: block;
    }

    .carousel-container {
        z-index: 1;
    }

    .carousel-indicators {
        display: none;
    }

    .carousel-control-prev {
        /* left: 0; */
        display: none;
    }

    .footer-link {
        display: none;
    }

    .footer-icons svg {
        font-size: 47px;
        margin-right: 8px;
        color: white;
    }

    .footer-icons {
        display: flex;
        margin-top: 6px;
        margin-bottom: 9px;
        margin-left: 63px;
    }

    .derechos-footer {
        margin-top: 39px
    }

    .home-content-right img {
        display: none;
    }

    .home-content {
        display: flex;
        margin-top: 7rem;
        margin-left: 0rem;
        margin-right: 1rem;
    }

    .home-content-left {
        flex: 1 1;
        margin-left: 20px;
    }

    .home-content-left h2 {
        font-size: calc(2.325rem + .9vw);
        font-weight: 600;
        text-align: center;
    }

    .conocenos-button {
        display: flex;
        padding: 10px 86px;
        background-color: #0C2748;
        color: #fff;
        text-decoration: none;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        justify-content: center;
        margin-left: 70px;
        margin-right: 72px;
    }

    .center-box-oficial {
        padding-bottom: 28px;
        padding-top: 72px;
    }

    .center-box-oficial img {
        width: 8rem;

    }

    .left-box-oficial img {
        width: 12rem;
    }


    .right-box-oficial img {
        width: 12rem;
    }


}



@media screen and (min-width: 769px) {
    nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #0C2748;
        padding: 0.5rem;
        position: relative;
    }

    .menu-toggle {
        display: none;
    }

    .menu-options {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: auto;
        position: static;
        padding: 0;
        visibility: visible;
        opacity: 1;
        font-size: 19px;
    }

    .menu-options.hide {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    .menu-options a {
        color: white;
        text-decoration: none;
        padding: 1rem;
        display: block;
    }

    .derechos-footer {
        margin-top: 39px
    }

    .center-box-oficial img {
        width: 8rem;

    }


}

/*CLIMA*/
.weather-component {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 48px;
    height: 263px;
}

.weather-info {
    text-align: center;
}

.weather-city {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.weather-temp {
    font-size: 1.2rem;
    margin: 5px 0;
}

.weather-description {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
}

.weather-humidity,
.weather-wind {
    font-size: 0.9rem;
    color: #777;
    margin: 3px 0;
}

.weather-error {
    color: #ff4444;
    font-weight: bold;
}

/* MARCAS */

.brands-container {
    text-align: center;
    margin-top: 71px;
    box-shadow: 0 12px 8px rgba(0, 0, 0, 0.1);
}

.brands-container h2 {
    font-size: calc(2.325rem + .9vw);
    font-weight: 600;
    text-align: center;
}

.brands-images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.brands-images img {
    width: 156px;
    height: auto;
    margin-bottom: 44px;
    margin-left: 97px;
    margin-right: 60px;
}

@media screen and (max-width: 768px) {
    .brands-images img {
        width: 100px;
        /* Ajusta el tamaño para dispositivos móviles */
    }

    .brands-images img {
        height: auto;
        margin-top: 17px;
        margin-bottom: 20px;
        margin-left: 67px;
        margin-right: -5px;
    }

    .img-zf-car img {
        display: flex;
        gap: 44px;
        flex-wrap: wrap;
        position: relative;
        margin-left: 0rem;
        margin-right: 5rem;
        max-width: 100%;
        max-height: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .img-zf-car img {
        padding: 1rem;
        /* Ajusta esto según tus necesidades o establece a 0 si no quieres padding */
        margin-left: 0rem;
        /* Ajusta este margen según tus necesidades para móviles */
        margin-right: 0rem;
        /* Ajusta este margen según tus necesidades para móviles */
    }
}

/* DOLAR HOY */
.weather-and-dolar {
    display: flex;
    justify-content: center;
}

.weather-and-dolar-container {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    /* Ajusta según sea necesario */
    margin: 0 auto;
    /* Centra el contenedor */
}

.container__img {
    max-width: 100%;
    border-width: 0;
    vertical-align: middle;
    margin-top: -83px;
}

.inframeDolar {
    width: 97%;
    height: 305px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 2px 4px 4px;
    border: 1px solid rgb(188, 188, 188);
    margin-top: 23px;
    margin-bottom: -5px;
}

.inframeDolar img {
    max-width: 100%;
    border-width: 0;
    vertical-align: middle;
    margin-top: -83px;
}

.logout-button {
    background-color: #0C2748;
    color: white;
    border: 2px solid #0C2748;
    /* Border temporal para depuración */

    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
}

.logout-button:hover {
    background-color: #084C77;
}