/* Nosotros.css */
.body-nosotros {
    background-color: var(--background);
    display: grid;
    place-items: center;

    padding: 24px;
    font-size: 16px;
    font-family: Poppins, sans-serif;
}

.container {
    display: flex;
    flex-wrap: wrap;
    /* padding: 5rem 0rem 3rem 0rem; */
    justify-content: center;
}

.card {
    display: flex;
    border-radius: 24px;
    background-color: white;
    cursor: pointer;
    overflow: hidden;
    width: 17rem;
    height: 30rem;
    margin-left: 10px;
    position: relative;
    margin: 3rem 4rem 3rem 1rem;

}

.card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.background {
    flex: 1 1 250px;
}

.background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    flex: 3 1 350px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 16px;
}

.content h2 {
    font-size: clamp(1.2rem, 1.3vw, 1.8rem);
    font-weight: 700;
    margin-bottom: clamp(0.35rem, 2vw, 0.55rem);
    text-align: center;
}

.content>p {
    font-size: clamp(1.19rem, 4.75vw, 1.1rem);
    font-weight: 400;
    margin: 6px 0;
    text-align: center;
}

.content a {
    color: var(--primary-violet);
}

.chips {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 12px 0px 16px 0px;
}

.chip {
    border-radius: 12px;
    padding: 10px;
    font-size: 15px;
    background-color: var(--gray);
    transition: all 0.3s;
    font-weight: 500;
}

.chip:hover {
    background-color: var(--primary-violet);
    color: var(--gray);
}

.action-buttons {
    border-top: 1px solid var(--gray);
    padding-top: -4px;
    /* gap: 12px; */
    /* display: flex; */
    margin-top: 4rem;
    flex-wrap: wrap;
    width: 15rem;
    height: -11rem;
}

.action-buttons a {
    background-color: var(--primary);
    color: white;
    padding: 12px;
    text-decoration: none;
    border-radius: 12px;
    outline: none;
    border: none;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    flex: 1 0 240px;
    max-width: 100%;
}

.action-buttons a:hover {
    text-decoration: underline;
}

.action-buttons a.secondary {
    /* background-color: inherit; */
    /* color: var(--primary); */
    border: 1px solid var(--primary);
    flex: 1 0 81px;
    margin-top: -6rem;
    margin-left: 0rem;
    background-color: #0c2748;
    color: white;
    /* width: 0rem; */

}

@media screen and (max-width: 768px) {
    .container {
        display: flex;
        flex-wrap: wrap;
        padding: 5rem 0rem 0rem 2rem;
        justify-content: center;
    }
}