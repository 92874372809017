.body {
    background-color: #f7f7f7;
}

.detalles-container {
    display: flex;
    margin: 67px auto;
    max-width: 800px;
    margin-top: -1rem;
}

.image-container {
    width: 40%;
    margin-right: 20px;

    img {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 10px;
        cursor: pointer;
    }
}

.details-content {
    width: 60%;

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
        color: #555;
    }

    p {
        font-size: 1rem;
        margin-top: 20px;
        overflow-wrap: break-word;
        margin-right: 5rem;
    }

    .product-code {
        font-size: 1.2rem;
        margin-top: 18px;
        margin-right: 19rem;
        margin-left: 0px;
        width: 11rem;
        text-align: center;
    }
}

.buy-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 15px;
    cursor: pointer;
}

.buy-button:hover {
    background-color: #0056b3;
}

.buy-button2 {
    position: absolute;
    left: 1.1rem;
    padding: 9px 20px;
    background-color: #007BFF;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.buy-button2:hover {
    background-color: #0056b3;
}
.buy-button3 {
    position: absolute;
    left: 1.1rem;
    margin-top: 2rem;
    padding: 9px 20px;
    background-color: #007BFF;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.buy-button3:hover {
    background-color: #0056b3;
}

@media screen and (max-width: 768px) {
    .detalles-container {
        display: flex;
        margin: 65px auto;
        flex-wrap: wrap;
    }

    .main-image img {
        width: 86%;
        transition: transform 0.3s ease-in-out;
        margin-left: 2rem;
    }

    .details-content {
        width: 61%;
        margin-top: -13rem;
        margin-right: 10rem;
        margin-left: 6rem;
    }
}