/* Products.css */
.products-container-general {
    text-align: center;
    padding: 20px;
}

.product-grid-general {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 23px;
}

.product-general-card {
    width: 20rem;
    height: 27rem;
    margin-bottom: 51px;
    padding: 0px;
    /* margin: 5px 0px 0px 4px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-left: 10px;
    position: relative;
}

.product-general-card:hover {
    transform: scale(1.05);
}

.product-general-card img {
    width: 51%;
    height: auto;
    border-radius: 8px;
}

.card-general-content {
    padding: 15px;
    height: calc(100% - 100px);
    /* Ajusta la altura máxima según tus necesidades */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-general-code {
    background-color: #0C2748;
    color: white;
    margin: 13px;
    padding: 8px;
    border-radius: 0 0 8px 8px;
}

.card-general-content h3 {
    font-size: 20px;
    /* Tamaño de fuente predeterminado */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-general-content h3.long-title {
    font-size: 16px;
    /* Tamaño de fuente más pequeño para títulos largos */
}

.more-info-button-general {
    display: inline-block;
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.more-info-button-general:hover {
    background-color: #0056b3;
}

.product-grid-general {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    justify-items: end;
    margin-bottom: 20px;
    /* Añade espacio al final para la paginación */
}

.pagination-container {
    text-align: center;
    margin-top: 69px;
}

.pagination-container button {
    display: inline-block;
    padding: 8px 16px;
    background-color: white;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-left: 7px;
}

.pagination-container button:hover {
    background-color: #0056b3;
}

.pagination-container span {
    font-size: 33px;
    margin-left: 6px;
}

.search-container {
    margin-bottom: 20px;
    align-items: center;
}

.search-container input {
    padding: 8px;
    margin-right: 10px;

}

.search-container select {
    padding: 8px;
}

.search-container button {
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    margin-left: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-container button:hover {
    background-color: #0056b3;
}

