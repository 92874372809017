.image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-right: 10px;
    margin-left: -10px;
    margin-bottom: 16rem;
}

.main-image {
    width: 80%;
    margin-bottom: -17px;
}

.details-content {
    width: 62%;
    margin-top: 14rem;
    margin-right: 6rem;
    margin-left: 10rem;
}

.main-image img {
    width: 191%;
    transition: transform 0.3s ease-in-out;
    margin-left: -10rem;
}

.thumbnail-container {
    display: flex;
    overflow-x: auto;
    justify-content: center;
    margin-top: 10px;
}

.thumbnail-container img {
    width: 80px;
    height: 80px;
    margin: 0 5px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.thumbnail-container img.selected,
.thumbnail-container img:hover {
    opacity: 0.7;
}

@media screen and (max-width: 768px) {
    .main-image {
        width: 100%;
    }

    .thumbnail-container {
        margin-top: 5px;
    }

    .detalles-container {
        display: flex;
        margin: 65px auto;
    }
}
@media screen and (max-width: 768px) {
    .detalles-container {
        display: flex;
        margin: 65px auto;
        flex-wrap: wrap;
    }

    .main-image img {
        width: 86%;
        transition: transform 0.3s ease-in-out;
        margin-left: 2rem;
    }

    .details-content {
        width: 61%;
        margin-top: -13rem;
        margin-right: 10rem;
        margin-left: 6rem;
    }
}