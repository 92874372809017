.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease;
    /* Transición de opacidad para el loader */
    opacity: 1;
}

.loader-container img {
    width: 70px;
    /* Ajusta el ancho de la imagen según tus necesidades */
    height: auto;
    /* Mantiene la proporción de aspecto */
}

.loader-image {
    animation: spin 4s linear infinite;
    width: 100px;
    /* Ajusta el ancho de la imagen según tus necesidades */
    height: auto;
    /* Mantiene la proporción de aspecto */
    opacity: 1;
    /* Establece una animación de rotación */
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}