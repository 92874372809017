.contacto-container {
    margin: 1rem auto;
    text-align: center;
    text-decoration: none;
}

h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.redes-sociales {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-content: center;
    flex-wrap: wrap;
    text-decoration: none;
    margin-top: 4rem;
    margin-bottom: 6rem;

}

.red-social {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.2rem;
    color: #333;
    transition: color 0.3s ease;
    width: 37rem;
    color: white;
    background-color: #0C2748;
    transition: color 0.3s ease;
    box-shadow: -1px 4px 12px 0px rgba(12, 39, 72, 1);
    text-decoration: none;
}

.ubicacion {
    display: flex;
    /* gap: 10px; */
    font-size: 1.2rem;
    /* color: #333; */
    /* transition: color 0.3s ease; */
    /* width: 60rem; */
    margin-left: 4rem;
    margin-right: 4rem;
    color: white;
    background-color: #0C2748;
    transition: color 0.3s ease;
    box-shadow: -12px 8px 20px 0px rgba(12, 39, 72, 1);
    /* text-decoration: none; */
    justify-content: center;
}

.red-social a {
    text-decoration: none;
    color: white
}

.red-social:hover {
    color: #007BFF;
}

.red-social span {
    white-space: nowrap;
}

.direccion {
    margin-top: 20px;
}

iframe {
    width: 89%;
    margin-bottom: 4rem;
}

.medios {
    align-content: space-between;
    margin-bottom: 21px;
    margin-top: 2rem;
    color: #0C2748;
}

@media screen and (max-width: 768px) {

    .redes-sociales {
        display: flex;
        flex-direction: column;
        gap: 20px;
        /* width: 0rem; */
        flex-wrap: wrap;
        text-decoration: none;
        margin-top: 4rem;
        margin-bottom: 6rem;
        align-content: space-around;
        align-items: center;

    }

    .red-social {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1.2rem;
        color: #333;
        transition: color 0.3s ease;
        width: 22rem;
        color: white;
        background-color: #0C2748;
        transition: color 0.3s ease;
        box-shadow: -1px 4px 12px 0px rgba(12, 39, 72, 1);
        text-decoration: none;
    }


}