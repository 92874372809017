/* Products.css */
.products-container-general {
    text-align: center;
    padding: 20px;
}

.product-filtro-general {
    display: flex;
    justify-content: center;
    gap: 44px;
    padding: 65px 103px 10rem 181px;
    /* justify-items: center; */
    margin-top: 3rem;
    /* margin-left: 9rem; */
    margin-right: 5rem;
    /* margin-bottom: 23rem; */
    /* width: 75%; */
    flex-wrap: wrap;
    position: relative;
}

.product-filtro-general {
    a {
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
        text-decoration: none;
    }
}

.product-filtro-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    width: 22rem;
    height: 26rem;
    ;
}

.product-filtro-card:hover {
    transform: scale(1.05);
}

.product-filtro-card img {
    width: 118%;
    height: auto;
    border-radius: 10px;
    margin-left: -2rem;
    margin-top: 0rem;
    margin-bottom: -3rem;
}


.card-filtro-content {
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5rem;
    white-space: nowrap;
    margin-top: -1rem;
    color: #0C2748;
}


.card-filtro-content h3 {
    font-size: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5rem;
    white-space: nowrap;
    margin-top: 4.8rem;
    text-align: center;
}


.card-filtro-content h3.long-title {
    font-size: 16px;
    /* Tamaño de fuente más pequeño para títulos largos */
}

.more-info-button-general {
    display: inline-block;
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-right: 5rem;
    margin-left: 5rem;
}

.more-info-button-general:hover {
    background-color: #0056b3;
}


@media screen and (max-width: 375px) {
    .products-container-general {
        text-align: center;
        padding: 20px;
    }

    .product-filtro-general {
        display: flex;
        justify-content: center;
        gap: 44px;
        padding: 65px 103px 10rem 181px;
        /* justify-items: center; */
        margin-top: 3rem;
        /* margin-left: 9rem; */
        margin-right: 5rem;
        /* margin-bottom: 23rem; */
        /* width: 75%; */
        flex-wrap: wrap;
        position: relative;
    }

    .product-grid-general {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
        justify-items: center;
        margin-top: 9rem;
        margin-left: 3rem;
        margin-right: -27rem;
        width: 65%;
    }

    .product-general-card {
        border: 1px solid #ccc;
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
        width: 85%;
        height: 400px;
    }

    .product-general-card:hover {
        transform: scale(1.05);
    }

    .product-general-card img {
        width: 48%;
        height: auto;
        border-radius: 8px;
        margin-left: 5rem;
        margin-top: 2rem;
        margin-bottom: -5rem;
    }

    .card-general-content h3.long-title {
        font-size: 16px;
        /* Tamaño de fuente más pequeño para títulos largos */
    }

    .more-info-button-general {
        display: inline-block;
        padding: 8px 16px;
        background-color: #007BFF;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;
        transition: background-color 0.3s ease;
        margin-right: 5rem;
        margin-left: 5rem;
    }

    .more-info-button-general:hover {
        background-color: #0056b3;
    }

    footer {
        background-color: #0C2748;
        color: white;
        text-align: center;
        padding: 1rem;
        position: absolute;
        bottom: -24rem;
        width: 100%;
        height: auto;
    }
}