.pdf-list-container {
    padding: 20px;
    background-color: #f5f5f5;
    color: #0C2748;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 52rem;
 
}

.pdf-list-content {
    max-width: 800px;
    width: 100%;
    margin-bottom: 4rem;
}

.banner-left,
.banner-right {
    position: absolute;
    top: 29px;
    bottom: 0;
    width: 155px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.banner-left {
    left: 0;
    margin-left: 1.5rem;
}

.banner-right {
    right: 0;
    margin-right: 1.5rem;
}

.banner-left video,
.banner-right video {
    max-width: 97%;
    height: auto;
}

@media (max-width: 768px) {

    .banner-left,
    .banner-right {
        display: none;
    }

    .pdf-list-content {
        max-width: 100%;
    }
}

.title {
    color: #0C2748;
    margin-bottom: 4rem;
    margin-top: 1rem;
    text-align: center;
}

.pdf-list-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.category-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.category-button {
    padding: 10px 20px;
    background-color: #0C2748;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.category-button:hover,
.category-button.active {
    background-color: #083366;
}

.pdf-options {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 19px;
    margin-bottom: 6rem;
}

.pdf-button {
    padding: 10px;
    background-color: #0C2748;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: 150px;
    text-align: center;
}

.pdf-button:hover {
    background-color: #083366;
}

.pdf-list {
    text-align: center;
    padding: 20px;
    color: #0C2748;
}

.categories {
    margin-bottom: 20px;
    text-align: center;
}

.categories button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #0C2748;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    width: 10rem;
    margin-bottom: 2rem;
}

.categories button:hover {
    background-color: #06407a;
}

.pdf-download-button {
    position: relative;
    margin: 10px;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #0C2748;
    color: #0C2748;
    width: 18rem;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pdf-download-button::after {
    content: '\f063';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    font-size: 16px;
    color: white;
    background-color: #0C2748;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    transition: transform 0.3s ease-in-out;
    margin-left: 10px;
}

.pdf-download-button:hover::after {
    animation: bounce 0.6s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(5px);
    }

    60% {
        transform: translateY(3px);
    }
}

@media only screen and (max-width: 375px) and (max-height: 667px) {
    .categories button {
        margin-top: 7px;
        padding: 10px 20px;
        background-color: #0C2748;
        color: white;
        border: none;
        cursor: pointer;
        font-size: 16px;
        border-radius: 5px;
        width: 19rem;
        margin-bottom: 2rem;
    }
}