/* Products.css */
.products-container-general {
    text-align: center;
    padding: 20px;
}

.product-grid-general {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 7rem;
}

.product-general-generalcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 6rem;
    margin-left: 1rem;
    margin-right: 4rem;
}

.pgh2 {
    font-size: 2rem;
    margin: 1rem 0rem 2rem 0rem;
    text-align: center;
    background-color: #0C2748;
    color: white;
}

.product-general-general {
    width: 20rem;
    height: 26rem;
    margin-bottom: 168px;
    padding: 0px;
    /* margin: 5px 0px 0px 4px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-left: 57px;
    position: relative;
}

.product-general-general img {
    width: 51%;
    height: auto;
    border-radius: 8px;
    
}

.product-general-card {
    width: 20rem;
    height: 27rem;
    margin-bottom: 51px;
    padding: 0px;
    margin: 5px 0px 0px 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-left: 10px;
    position: relative;
}

.product-general-card:hover {
    transform: scale(1.05);
}

.product-general-general:hover {
    transform: scale(1.05);
}

.product-general-card img {
    width: 51%;
    height: auto;
    border-radius: 8px;
}


.card-general-content {
    padding: 9px;
    height: calc(72% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #f7f7f7;
}

.card-general-general {
    padding: 9px;
    height: calc(75% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #f7f7f7;
    text-align: center;
}

.product-general-code {
    background-color: #0C2748;
    color: white;
    /* margin: 9px; */
    /* margin-top: 18rem; */
    padding: 0px;
}

.product-general-code-general {
    background-color: #0C2748;
    color: white;
    /* margin: 9px; */
    /* margin-top: 18rem; */
    padding: 0px;
    text-align: center;
    border-radius: 0 0 8px 8px;
}

.card-general-content h3 {
    font-size: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* margin-bottom: -2rem; */
    white-space: nowrap;
    margin-top: -1rem;
}

.card-general-general h3 {
    font-size: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* margin-bottom: -2rem; */
    white-space: nowrap;
    margin-top: -2rem;
}

.card-general-content p {
    margin-top: -5px;
}

.card-general-generalp {
    margin-top: -5px;
}

.card-general-content h3.long-title {
    font-size: 16px;
    /* Tamaño de fuente más pequeño para títulos largos */
}

.card-general-general h3.long-title {
    font-size: 16px;
    /* Tamaño de fuente más pequeño para títulos largos */
}

.more-info-button-general {
    display: inline-block;
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-right: 5rem;
    margin-left: 5rem;
    text-align: center;
}

.more-info-button-general:hover {
    background-color: #0056b3;
}



.pagination-container {
    text-align: center;
    margin-top: 69px;
}

.pagination-container button {
    display: inline-block;
    padding: 8px 16px;
    background-color: white;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-left: 7px;
}

.pagination-container button:hover {
    background-color: #0056b3;
}

.pagination-container span {
    font-size: 33px;
    margin-left: 6px;
}

.search-container {
    margin-bottom: 20px;
    align-items: center;
}

.search-container-general {
    margin-bottom: 49px;
    padding: 8px;
    align-items: center;
    margin-left: 24rem;
    margin-top: 4rem;
}

.search-container input {
    padding: 8px;
    margin-right: 10px;

}

.search-container-general input {
    padding: 8px;
    margin-right: 10px;
}

.search-container-general select {
    padding: 8px;
}

.search-container-general button {
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    margin-left: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-container select {
    padding: 8px;
}

.search-container button {
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    margin-left: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-container button:hover {
    background-color: #0056b3;
}

.search-container-general button:hover {
    background-color: #0056b3;
}

@media screen and (max-width: 375px) {
    .product-general-general {
        border: 1px solid #ccc;
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
        width: 75%;
        height: 400px;
    }

    .search-container-general {
        margin-bottom: 49px;
        padding: 8px;
        align-items: center;
        /* margin-left: 24rem; */
        margin-top: 4rem;
    }


    .search-container-general button {
        padding: 8px 16px;
        background-color: #007BFF;
        color: #fff;
        border: none;
        margin-left: 14px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
}

@media screen and (max-width: 768px) {
    .product-general-card {
        width: 98%;
        /* Ocupa todo el ancho en dispositivos móviles */
    }

    .product-grid-general {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .product-general-card img {
        width: 72%;
        margin-left: 40px;
        /* margin-right: 80px; */
        /* align-content: space-around; */
        height: auto;
        border-radius: 8px;
    }

    
}

@media screen and (max-width: 768px) {
    .product-general-card {
        width: 55%;
        /* Ocupa todo el ancho en dispositivos móviles */
    }

    .product-grid-general {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .product-general-card img {
        width: 49%;
        margin-left: 10px;
        /* margin-right: 80px; */
        /* align-content: space-around; */
        height: auto;
        border-radius: 8px;
    }
}

@media screen and (max-width: 375px) {
    .product-general-card {
        width: 98%;
        /* Ocupa todo el ancho en dispositivos móviles */
    }

    .product-grid-general {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .product-general-card img {
        width: 64%;
        height: auto;
        border-radius: 8px;
        margin-left: 0rem;
        margin-top: -1rem;
        margin-bottom: 0rem;
    }

    .card-general-content {
        margin-top: 15px;
        text-align: center;
        background-color: #f7f7f7;
    }
}