body {
  padding-bottom: 4rem;
  /* Ajusta según necesites */
}
.form-container {
  width: 100%;
    max-width: 400px;
    margin: 5rem auto;
    margin-bottom: 3rem;
    /* Agrega esta línea */
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 39px rgba(0, 0, 0, 0.1);
}

.form-title {
  text-align: center;
  color: #0C2748;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-input:focus {
  border-color: #0C2748;
  outline: none;
}

.form-button {
  width: 100%;
  padding: 10px;
  background-color: #0C2748;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #174A78;
}

/* Estilos para el formulario de registro */
form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px -1px rgba(0, 0, 0, 0.1);
 
}

form h2 {
  margin-bottom: 20px;
  color: #0C2748;
}

form input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

form button {
  width: 100%;
  padding: 12px;
  background-color: #0C2748;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

form button:hover {
  background-color: #174A78;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.form p {
  margin-top: 14px;
  margin-bottom: 4px;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

/* Estilos para el mensaje de validación de la contraseña */
.validation-list {
  color: #dc3545;
  /* Color rojo para el texto de validación */
  margin-top: 5px;
  padding-left: 20px;
  /* Espaciado a la izquierda para la lista */
  list-style-type: disc;
  /* Usar puntos para la lista */
}

.validation-message {
  margin-bottom: 5px;
  /* Espacio entre los puntos de la lista */
}
.error {
  border: 2px solid red;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.validation-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5px;
}

.validation-message {
  color: red;
  font-size: 0.9em;
}

.popup-container {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: green;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.success-popup {
  background-color: green;
}