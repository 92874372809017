.highlighted-products {
    margin-top: 106px;
    margin-left: 38px;
    margin-right: 38px;
}

.highlighted-products h2 {
    font-size: calc(2.325rem + .9vw);
    font-weight: 600;
    text-align: center;
}

.product-cards {
    display: flex;
    flex-wrap: wrap;
    /* Permite que las tarjetas se envuelvan en dispositivos pequeños */
    justify-content: center;
    margin-top: 23px;
}

.product-card {
    width: 20rem;
    height: 23rem;
    margin-bottom: 51px;
    padding: 0px;
    /* margin: 5px 0px 0px 4px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-left: 10px;
    position: relative;
}

.product-code {
    background-color: #0C2748;
    color: white;
    /* width: 96%; */
    margin-left: 12px;
    margin-right: 12px;
}

.product-card:hover {
    transform: scale(1.05);
}

.product-card img {
    width: 50%;
    margin-left: 5rem;
    margin-right: 5rem;
    height: auto;
    border-radius: 8px;
}

.card-content {
    margin-top: 45px;
    text-align: center;
    background-color: #f7f7f7;
    height: 16rem;
}

.more-info-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.more-info-button:hover {
    background-color: #0056b3;
}

.brand-label {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}

.brand-label img {
    width: 40px;
    /* Ajusta el tamaño según sea necesario */
    height: auto;
    border-radius: 50%;
}

.brand-logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    margin-right: 155px;
    margin-left: -82px;
}

.brand-logo img {
    width: 33%;
    margin-left: 6rem;
    margin-right: 4rem;
    height: auto;
    border-radius: 14px;
}

@media screen and (max-width: 768px) {
    .product-card {
        width: 98%;
        /* Ocupa todo el ancho en dispositivos móviles */
    }

    .product-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .product-card img {
        width: 72%;
        margin-left: 40px;
        /* margin-right: 80px; */
        /* align-content: space-around; */
        height: auto;
        border-radius: 8px;
    }
}

@media screen and (max-width: 768px) {
    .product-card {
        width: 55%;
        /* Ocupa todo el ancho en dispositivos móviles */
    }

    .product-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .product-card img {
        width: 72%;
        margin-left: 40px;
        /* margin-right: 80px; */
        /* align-content: space-around; */
        height: auto;
        border-radius: 8px;
    }
}

@media screen and (max-width: 375px) {
    .product-card {
        width: 98%;
        margin-bottom: 10rem;
        /* Ocupa todo el ancho en dispositivos móviles */
    }

    .product-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .product-card img {
        width: 72%;
        margin-left: 40px;
        /* margin-right: 80px; */
        /* align-content: space-around; */
        height: auto;
        border-radius: 8px;
    }

    .card-content {
        margin-top: 15px;
        text-align: center;
        background-color: #f7f7f7;
    }

    .brand-logo {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
        margin-right: 155px;
        margin-left: -82px;
    }

    .brand-logo img {
        width: 56%;
        margin-left: 5rem;
        margin-right: 4rem;
        height: auto;
        border-radius: 14px;
    }
}